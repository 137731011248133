<template>
	<DefaultButton @click="handleLoginWithGoogle" :isLoading="loading">
		<img class="mr-2" src="/web/icons/google.svg" alt="Google" />
		{{ $t('loginPage.loginWithGoogle') }}
	</DefaultButton>
</template>

<script setup lang="ts">
import { Capacitor } from '@capacitor/core'
import Button from '@/components/default/Button.vue'
import type { IUser } from '~/types/models/user.interface'

const props = defineProps<{
	redirectTo?: string
}>()

const loading = ref(false)
const GoogleAuth = ref<any>(null)
const $router = useRouter()
const userStore = useUserStore()
const { t } = $i18n()
const config = useRuntimeConfig()

const handleLoginWithGoogle = async () => {
	try {
		loading.value = true
		if (Capacitor.getPlatform() === 'web') {
			$captureEvent('login_google_attempt')
			location.href = `/api/auth/google?${props.redirectTo ? `redirectTo=${props.redirectTo}` : ''}`
		} else {
			const response = await GoogleAuth.value.signIn()

			const { user } = await $api<any>(`/auth/google/callback?code=${response.serverAuthCode}&skipRedirect=true`)

			userStore.login(user as IUser)
			$captureEvent('login_google_success')

			if (props.redirectTo) {
				$router.push(props.redirectTo)
				return
			}

			$router.push('/')
			const nuxtApp: any = useNuxtApp()
			nuxtApp.$requestPushNotifications()
		}
	} catch (error) {
		$sentryCaptureException(error)
		$toast({
			type: 'error',
			title: 'Oooops!',
			message: t('loginPage.loginError'),
		})
		$captureEvent('login_google_error')
	}
	loading.value = false
}

onMounted(() => {
	if (Capacitor.getPlatform() !== 'web') {
		import('@codetrix-studio/capacitor-google-auth').then(({ GoogleAuth: Auth }) => {
			GoogleAuth.value = Auth

			GoogleAuth.value.initialize({
				clientId: config.public.googleWebAppId,
				scopes: ['email', 'profile'],
			})
		})
	}
})
</script>
